// Here you can add other styles

.miValueBack {
  background: linear-gradient(to bottom right, rgba(75, 3, 92, 0.7), rgba(0, 0, 0, 0)),
    url('https://miinews.com/static/core/graphics/gold-covered-bkg-right.jpg') no-repeat;
  background-size: cover;
  background-position: top center;
  display: flex;
  align-items: center;
  color: #fff;
}

.miBackWhite {
  background: #fff;
}

.colorPurple {
  color: #4b035c;
}

.colorOrange {
  color: #f47042;
}

.miFontHeader {
  font-family: Gotham, sans-serif;
  font-weight: 750;
}

.miFontSubHeader {
  font-family: Gotham, sans-serif;
  font-weight: 800;
}

.miFontBody {
  font-family: Gotham, sans-serif;
  font-size: 16px;
  line-height: 32px;
}

.miBackGrey {
  background: #e9e9e9;
}

.miPurpleBack {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #2b3a92, #4b035c) no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  min-height: 30vh;
  max-height: 750px;
}

.banner-blue {
  padding: 5rem 0;
  background: #0063ba;
  color: #fff;
  font-weight: 800;
  font-size: 2rem;
}
